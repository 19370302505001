.complex-table {
  display: flex;
}

.total-table {
  padding-bottom: 20px;
}

.total-table > .table1 td {
  height: 20px;
}
  
  table {
    display: table;
    border-collapse: separate;
    box-sizing: border-box;
    text-indent: initial;
    border-spacing: 2px;
    border-color: var(--table-border-color);
    color: var(--content-text-color);
  }
  
  td {
    border: 1px var(--table-border-color) dotted;
  }
  
  th {
    height: 25px;
    text-align: center;
    color: var(--table-header-text-color);
    background-color: var(--table-header-background-color);
    font-weight: normal;
    padding: 6px;
    border: 1px var(--table-border-color) solid;
  }
  
  tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
  }

  .table1 {
    font-size: 12px;
    //border: 1px var(--table-border-color) solid;
    border-collapse: collapse;
  }
  
  .table1 td {
    padding: 4px;
    text-align: right;
  }
  
  th.td-green {
    background-color: var(--table-header-bg-green-color);
  }
  
  th.td-red {
    background-color: var(--table-header-bg-red-color);
  }
  
  td.td-green {
    background-color: var(--table-bg-green-color);
  }
  
  td.td-red {
    background-color: var(--table-bg-red-color);
  }
  
  th.td-longshort {
    width: 60px;
  }
  
  th.td-info {
    padding-left: 10px;
    text-align: left;
    width: 254px;
  }
  
  th.td-summ {
    width: 70px;
  }
  
  th.td-time {
    width: 40px;
  }
  
  div.td-text-ticker {
    font-size: 15px;
    font-weight: bold;
  }