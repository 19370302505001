.mat-mdc-select-panel {
  --mat-select-panel-background-color: var(--content-background-color); //выпадающие меню
  --mat-option-selected-state-label-text-color: var(--controls-secondary-control-color-60);
  --mat-option-label-text-color: var(--content-text-muted-color);
  --mat-option-hover-state-layer-color: var(--content-background-color);
  --mat-option-focus-state-layer-color: var(--content-background-color);
  --mat-option-selected-state-layer-color: var(--header-background-color);
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  min-width: 1500px !important;
}

.mat-icon {
  width: auto;
}

.mat-mdc-select-arrow-wrapper {
  padding-left: 5px;
}

.mat-button-toggle-group-appearance-standard {
  border: none !important;
}

.mat-mdc-button-ripple {
  color: var(--content-text-color) !important;
}

.mat-button-toggle-appearance-standard {
  color: var(--content-text-color) !important;
  background-color: var(--element-bg-color) !important;
}

.control-menu {
  position: absolute;
  top: 40px;
  left: 0;
  border-bottom: 1px var(--header-border-color) solid !important;
  height: 44px !important;
  background-color: var(--header-background-color) !important;
  color: var(--content-text-color) !important;
}

// разделитель
.light-splitter {
  width: 1px !important;
  height: 70%;
  border-right: 1px solid var(--element-splitter-color);
  padding: 0 5px !important;
  min-width: auto !important;
}

// тикеры на Дневной
.small-tickers-control {
  width: fit-content;
  font-size: 15px;
}

// выбор даты
.date-section {
  display: flex;
  flex-direction: row-reverse;
  justify-content: unset;
  padding-left: 5px;
  --mdc-icon-button-disabled-icon-color: var(--header-menu-item-muted-color); //иконка неактивная
}

.date-input {
  border: none;
  background-color: transparent;
  width: 80px;
  padding: 0;
  font-size: 15px;
  text-align: right;
}

.date-section>.date-icon svg {
  height: 16px;
  width: 16px;
  //color: var(--content-text-color);
}

.date-section>.date-icon button {
  height: auto;
  width: auto;
  padding: 0 5px;
  margin: 0;
}

// количество дней на Дневной
.small-days-control {
  width: auto;
  //color: var(--content-text-color) !important;
}

.small-days-control section {
  display: inline-flex;
  padding-left: 5px;
}

.small-days-control mat-icon {
  font-size: 16px;
  height: auto;
  padding: 0 5px;
}

.small-days-control input {
  font-size: 15px;
  width: 35px;
  text-align: right;
}

// выбор ЮЛ/ФЛ
.small-pos-control {
  width: 80px;
  padding-left: 5px;
  font-size: 15px
}

.small-pos-control mat-icon {
  font-size: 16px;
  display: inline-flex;
  padding: 12px 5px 0;
  align-items: center;
}

// группы переключателей
.switch-control-group {
  display: flex;
  border: none;
  padding-left: 8px;
}

.small-switch-control mat-icon {
  display: flex;
  font-size: 16px;
  margin-left: -8px;
}

.small-switch-control mat-button-toggle {
  height: 24px;
  width: 24px;
  border: none;
  border-radius: 2px;
  margin: 0 2px;
  line-height: normal;
}

.small-switch-control mat-button-toggle button span {
  padding: 0;
}

.mat-button-toggle-checked {
  background-color: var(--main-blue-color);
  color: #fff !important;
}

// исторический режим
.small-select-time mat-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  //margin-left: -15px;
}

// обновить
.small-refresh mat-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  //margin-left: -15px;
}

.mat-mdc-progress-spinner {
  margin: auto;
}

.active-item {
  color: #fff;
  background-color: var(--main-blue-color);
  font-weight: normal;
}

// инструмент на всех кроме Дневной
.small-ticker-control {
  width: fit-content;
  font-size: 15px;
}

.active-ticker {
  color: var(--main-blue-color);
  font-weight: bold;
  padding: 0 5px;
}

// Таймфрейм
.small-tf-control {
  display: flex;
  font-size: 15px;
}

.small-tf-control span {
  padding-left: 8px;
}

// Уведомления на Побарный
.small-notify-control {
  font-size: 15px;
  display: inline-flex;
  align-items: center;
}

.small-notify-control button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.small-notify-control mat-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: var(--content-text-color);
}