.charts table td {
    border: none !important;
  }

  table > tr:first-child > td:nth-child(2) > div > canvas {
    border: solid 0.5px var(--table-border-color);
  }

  .tv-lightweight-charts {
    margin-top: 20px;
    margin-left: 15px;
  }
  
  .tv-lightweight-charts > table {
    border-spacing: 0;
  }