:root {
  --font-family: "Inter", sans-serif;
  --second-family: "IBM Plex Sans", sans-serif;
  --third-family: "Metro Sans", sans-serif;
  --font3: "Roboto", sans-serif;
  --font4: "Gilmer", sans-serif;
}

[data-theme="light"] {
  --header-background-color: #f5f5f5;
  --header-border-color: #cccccc;
  --header-menu-item-color: #231f20;
  --header-menu-item-muted-color: rgba(0, 0, 0, .15);

  --content-background-color: #ffffff;
  --content-text-color: #3d3d3d;
  --content-text-muted-color: #6c757d;
  --content-link-color: #2985e7;

  --table-border-color: #c6c6c6;
  --table-header-background-color: #888888;
  --table-header-text-color: #ffffff;
  --table-header-bg-red-color: #756666;
  --table-header-bg-green-color: #667566;
  --table-bg-red-color: #FFF7F7;
  --table-bg-green-color: #F6FFF6;

  --table-header-selected-bg-color: #3C3C3D;

  --main-blue-color: #4379d0;
  --main-red-color: #d04343;

  --element-bg-color: #ffffff;
  --element-splitter-color: #e7e7e7;

  --text-color-00: #fff; //v
  --text-color-10: #e3e5eb; //v
  --text-color-20: #c8cbd7; //v
  --text-color-30: #adb1c3; //v
  --text-color-40: #9197b0; //v
  --text-color-50: #767e9c; //v
  --text-color-60: #5a6689; //v
  --text-color-70: #474d65; //v
  --text-color-80: #333743; //v
  --text-color-90: #202121; //v
  --text-color-100: #070707; //v
  --controls-primary-cta-layer-color-10: #fff;
  --controls-primary-cta-layer-color-20: #fff;
  --controls-primary-cta-layer-color-40: #fff;
  --controls-primary-cta-layer-color-60: #fff;
  --controls-primary-cta-layer-color-80: #fff;
  --controls-primary-cta-layer-color-90: #fff;
  --controls-primary-cta-color-10: #ededfc;
  --controls-primary-cta-color-20: #c6c1ff;
  --controls-primary-cta-color-40: #8981ef;
  --controls-primary-cta-color-60: #4c40e6;
  --controls-primary-cta-color-80: #291ccb;
  --controls-primary-cta-color-90: #1409a0;
  --controls-secondary-control-layer-color-10: #fff;
  --controls-secondary-control-layer-color-20: #fff;
  --controls-secondary-control-layer-color-40: #fff;
  --controls-secondary-control-layer-color-60: #fff;
  --controls-secondary-control-layer-color-80: #fff;
  --controls-secondary-control-layer-color-90: #fff;
  --controls-secondary-control-color-10: #dbeafe; //v
  --controls-secondary-control-color-20: #93c5fd; //v
  --controls-secondary-control-color-40: #60a5fa; //v
  --controls-secondary-control-color-60: #2563eb; //v
  --controls-secondary-control-color-80: #1d4ed8; //v
  --controls-secondary-control-color-90: #1e40af; //v
  --controls-neutral-control-layer-color-00: #fff; //v
  --controls-neutral-control-layer-color-10: #e4e6eb; //v
  --controls-neutral-control-layer-color-20: #c8ccd8; //v
  --controls-neutral-control-layer-color-30: #adb3c4; //v
  --controls-neutral-control-layer-color-40: #9199b1; //v
  --controls-neutral-control-layer-color-50: #76809d; //v
  --controls-neutral-control-layer-color-60: #5a6689; //v
  --controls-neutral-control-layer-color-70: #454e68; //v
  --controls-neutral-control-layer-color-80: #303648; //v
  --controls-neutral-control-layer-color-90: #1c1f27; //v
  --controls-neutral-control-layer-color-100: #070707; //v
  --controls-neutral-control-color-00: #fff; //v
  --controls-neutral-control-color-10: #fcfcfd; //v
  --controls-neutral-control-color-20: #f9f9fa; //v
  --controls-neutral-control-color-30: #f5f6f8; //v
  --controls-neutral-control-color-40: #f2f3f5; //v
  --controls-neutral-control-color-50: #eff0f3; //v
  --controls-neutral-control-color-60: #e5e7ec; //v
  --controls-neutral-control-color-70: #dbdde5; //v
  --controls-neutral-control-color-80: #d1d4dd; //v
  --controls-neutral-control-color-90: #c7cad6; //v
  --controls-neutral-control-color-100: #bdc1cf; //v
  --controls-divider-color-10: #f0f1f3;
  --controls-divider-color-20: #e1e3e7;
  --controls-divider-color-40: #d1d5db;
  --controls-divider-color-60: #c3c8cf;
  --controls-divider-color-80: #b5bbc4;
  --controls-divider-color-90: #a7aeb8;
  --background-main-background-color: #fff; //v
  --background-subheader-background-color: #fff; //v
  --background-body-background-color: #fff; //v
  --background-input-background-color: #fff; //v
  --background-dropdown-background-color: #fff; //v
  --background-shadow-color: #000; //v
  --system-system-green-10: #e8fcf1;
  --system-system-green-20: #a5e1bf;
  --system-system-green-40: #419e6a;
  --system-system-green-60: #00632b;
  --system-system-green-80: #00401c;
  --system-system-green-90: #002611;
  --system-system-red-10: #ffebeb;
  --system-system-red-20: #fc9595;
  --system-system-red-40: #d83232;
  --system-system-red-60: #b01212;
  --system-system-red-80: #8c0000;
  --system-system-red-90: #600;
  --system-system-blue-10: #d3e1fe;
  --system-system-blue-20: #7ea5f8;
  --system-system-blue-40: #4d82f3;
  --system-system-blue-60: #2563eb;
  --system-system-blue-80: #0037b3;
  --system-system-blue-90: #002987;
  --system-system-yellow-10: #fff5d5;
  --system-system-yellow-20: #ffde81;
  --system-system-yellow-40: #efb008;
  --system-system-yellow-60: #976400;
  --system-system-yellow-80: #724b00;
  --system-system-yellow-90: #4d2900;
  --system-ask-10: #ffebeb;
  --system-ask-20: #fc9595;
  --system-ask-40: #d83232;
  --system-ask-60: #b01212;
  --system-ask-80: #8c0000;
  --system-ask-90: #600;
  --system-bid-10: #e8fcf1;
  --system-bid-20: #a5e1bf;
  --system-bid-40: #419e6a;
  --system-bid-60: #00632b;
  --system-bid-80: #00401c;
  --system-bid-90: #002611;
  --navbar-navbar-background-color: #fff;
  --navbar-footer-background-color: #fff;
  --navbar-navbar-control-layer-color-10: #c6c1ff;
  --navbar-navbar-control-layer-color-20: #8981ef;
  --navbar-navbar-control-layer-color-40: #4c40e6;
  --navbar-navbar-control-layer-color-60: #291ccb;
  --navbar-navbar-control-layer-color-80: #1409a0;
  --navbar-navbar-control-layer-color-90: #08006d;
  --navbar-navbar-control-bg-color-10: #ededfc;
  --navbar-navbar-control-bg-color-20: #d9d7fe;
  --navbar-navbar-control-bg-color-40: #c6c1ff;
  --navbar-navbar-control-bg-color-60: #8981ef;
  --navbar-navbar-control-bg-color-80: #4c40e6;
  --navbar-navbar-control-bg-color-90: #291ccb;
}

[data-theme="dark"] {
  --header-background-color: #1d1d1d;
  --header-border-color: #3d3d3d;
  --header-menu-item-color: #ffffff;
  //--header-menu-item-muted-color: #8f8f8f;
  --header-menu-item-muted-color: rgb(255 255 255 / 15%);

  --content-background-color: #252525;
  --content-text-color: #c9c9c9;
  --content-text-muted-color: #6c757d;
  --content-link-color: #487eb7;

  --table-border-color: #3d3d3d;
  --table-header-background-color: #181818;
  --table-header-text-color: #8f8f8f;
  --table-header-bg-red-color: #291919;
  --table-header-bg-green-color: #161c16;
  --table-bg-red-color: #332929;
  --table-bg-green-color: #272d27;

  --table-header-selected-bg-color: #000000;

  --main-blue-color: #2b5785;
  --main-red-color: #852b2b;

  --element-bg-color: #2d292a;
  --element-splitter-color: #3d3d3d66;


  --text-color-00: #141A1E; //v
  --text-color-10: #2e363b; //v
  --text-color-20: #485158; //v
  --text-color-30: #626d74; //v
  --text-color-40: #7b8891; //v
  --text-color-50: #95a4ad; //v
  --text-color-60: #afbfca; //v
  --text-color-70: #cad5dc; //v
  --text-color-80: #e5eaee; //v
  --text-color-90: #fff;
  --text-color-100: #fff;
  --controls-primary-cta-layer-color-10: #fff;
  --controls-primary-cta-layer-color-20: #fff;
  --controls-primary-cta-layer-color-40: #fff;
  --controls-primary-cta-layer-color-60: #fff;
  --controls-primary-cta-layer-color-80: #fff;
  --controls-primary-cta-layer-color-90: #fff;
  --controls-primary-cta-color-10: #ededfc;
  --controls-primary-cta-color-20: #c6c1ff;
  --controls-primary-cta-color-40: #8981ef;
  --controls-primary-cta-color-60: #4c40e6;
  --controls-primary-cta-color-80: #291ccb;
  --controls-primary-cta-color-90: #1409a0;
  --controls-secondary-control-layer-color-10: #fff;
  --controls-secondary-control-layer-color-20: #fff;
  --controls-secondary-control-layer-color-40: #fff;
  --controls-secondary-control-layer-color-60: #fff;
  --controls-secondary-control-layer-color-80: #fff;
  --controls-secondary-control-layer-color-90: #fff;
  --controls-secondary-control-color-10: #1A2D5F; //v
  --controls-secondary-control-color-20: #1E3A8A; //v
  --controls-secondary-control-color-40: #1E40AF; //v
  --controls-secondary-control-color-60: #2563EB; //v
  --controls-secondary-control-color-80: #3B82F6; //v
  --controls-secondary-control-color-90: #60A5FA; //v
  --controls-neutral-control-layer-color-00: #141A1E; //v
  --controls-neutral-control-layer-color-10: #2E363B; //v
  --controls-neutral-control-layer-color-20: #485158; //v
  --controls-neutral-control-layer-color-30: #626D74; //v
  --controls-neutral-control-layer-color-40: #7B8891; //v
  --controls-neutral-control-layer-color-50: #95A4AD; //v
  --controls-neutral-control-layer-color-60: #AFBFCA; //v
  --controls-neutral-control-layer-color-70: #CAD5DC; //v
  --controls-neutral-control-layer-color-80: #E5EAEE; //v
  --controls-neutral-control-layer-color-90: #FFFFFF; //v
  --controls-neutral-control-layer-color-100: #FFFFFF; //v
  --controls-neutral-control-color-00: #141a1e; //v
  --controls-neutral-control-color-10: #1b2226; //v
  --controls-neutral-control-color-20: #232a2e; //v
  --controls-neutral-control-color-30: #2a3136; //v
  --controls-neutral-control-color-40: #32393e; //v
  --controls-neutral-control-color-50: #394146; //v
  --controls-neutral-control-color-60: #40494e; //v
  --controls-neutral-control-color-70: #485156; //v
  --controls-neutral-control-color-80: #4f585e; //v
  --controls-neutral-control-color-90: #566066; //v
  --controls-neutral-control-color-100: #5e686f; //v
  --controls-divider-color-10: #f0f1f3;
  --controls-divider-color-20: #e1e3e7;
  --controls-divider-color-40: #d1d5db;
  --controls-divider-color-60: #c3c8cf;
  --controls-divider-color-80: #b5bbc4;
  --controls-divider-color-90: #a7aeb8;
  --background-main-background-color: #141A1E; //v
  --background-subheader-background-color: #141A1E; //v
  --background-body-background-color: #141A1E; //v
  --background-input-background-color: #141A1E; //v
  --background-dropdown-background-color: #141A1E; //v
  --background-shadow-color: #fff; //v
  --system-system-green-10: #e8fcf1;
  --system-system-green-20: #a5e1bf;
  --system-system-green-40: #419e6a;
  --system-system-green-60: #00632b;
  --system-system-green-80: #00401c;
  --system-system-green-90: #002611;
  --system-system-red-10: #ffebeb;
  --system-system-red-20: #fc9595;
  --system-system-red-40: #d83232;
  --system-system-red-60: #b01212;
  --system-system-red-80: #8c0000;
  --system-system-red-90: #600;
  --system-system-blue-10: #d3e1fe;
  --system-system-blue-20: #7ea5f8;
  --system-system-blue-40: #4d82f3;
  --system-system-blue-60: #2563eb;
  --system-system-blue-80: #0037b3;
  --system-system-blue-90: #002987;
  --system-system-yellow-10: #fff5d5;
  --system-system-yellow-20: #ffde81;
  --system-system-yellow-40: #efb008;
  --system-system-yellow-60: #976400;
  --system-system-yellow-80: #724b00;
  --system-system-yellow-90: #4d2900;
  --system-ask-10: #ffebeb;
  --system-ask-20: #fc9595;
  --system-ask-40: #d83232;
  --system-ask-60: #b01212;
  --system-ask-80: #8c0000;
  --system-ask-90: #600;
  --system-bid-10: #e8fcf1;
  --system-bid-20: #a5e1bf;
  --system-bid-40: #419e6a;
  --system-bid-60: #00632b;
  --system-bid-80: #00401c;
  --system-bid-90: #002611;
  --navbar-navbar-background-color: #fff;
  --navbar-footer-background-color: #fff;
  --navbar-navbar-control-layer-color-10: #c6c1ff;
  --navbar-navbar-control-layer-color-20: #8981ef;
  --navbar-navbar-control-layer-color-40: #4c40e6;
  --navbar-navbar-control-layer-color-60: #291ccb;
  --navbar-navbar-control-layer-color-80: #1409a0;
  --navbar-navbar-control-layer-color-90: #08006d;
  --navbar-navbar-control-bg-color-10: #ededfc;
  --navbar-navbar-control-bg-color-20: #d9d7fe;
  --navbar-navbar-control-bg-color-40: #c6c1ff;
  --navbar-navbar-control-bg-color-60: #8981ef;
  --navbar-navbar-control-bg-color-80: #4c40e6;
  --navbar-navbar-control-bg-color-90: #291ccb;
}