/* You can add global styles to this file, and also import other style files */
//@import "@angular/material/prebuilt-themes/purple-green.css";

// Importing themes
@import "themes";
@import "tables";
@import "charts";
@import "control-panel";


html,
body {
  height: 100%;
  background-color: var(--content-background-color);
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: var(--content-text-color);
}

a {
  color: var(--content-link-color);
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-focus-icon-color: var(--main-blue-color);
  --mdc-radio-selected-hover-icon-color: var(--main-blue-color);
  --mdc-radio-selected-icon-color: var(--main-blue-color);
  --mdc-radio-selected-pressed-icon-color: var(--main-blue-color);
  --mat-mdc-radio-checked-ripple-color: var(--main-blue-color);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: var(--main-blue-color);
}

.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: var(--main-blue-color);
  --mdc-checkbox-selected-hover-icon-color: var(--main-blue-color);
  --mdc-checkbox-selected-icon-color: var(--main-blue-color);
  --mdc-checkbox-selected-pressed-icon-color: var(--main-blue-color);
}

.page-content {
  color: var(--content-text-color);
  --mdc-outlined-text-field-outline-color: var(--header-border-color);
  --mdc-outlined-text-field-label-text-color: var(--content-text-muted-color);
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-unselected-icon-color: var(--table-border-color);
}

.mat-button-toggle-checked {
  background-color: var(--main-blue-color) !important;
}

.mdc-label {
  color: var(--content-text-color);
}

.relative-5 {
  position: relative;
  top: -5px;
}

mat-month-view>table>thead>tr>th {
  color: inherit !important;
}

mat-pseudo-checkbox {
  display: none !important;
}

.mat-button-toggle:hover {
  background-color: transparent;
  //color: #000 !important;
}

mat-dialog-actions {
  padding: 15px !important;
}

.page-content {

  .mat-mdc-select,
  .mat-datepicker-input,
  .mat-datepicker-toggle,
  input,
  .mat-mdc-select-arrow {
    color: var(--content-text-color) !important;
  }
}


div.bold {
  font-weight: bold;
}

div.bold label {
  font-weight: normal;
}

.font-green {
  color: #77a45b;
}

.font-red {
  color: #d46f6c;
}

.font-blue {
  color: var(--main-blue-color);
}

.font-yellow {
  color: #edc356
}

.text-center {
  text-align: center !important;
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.date-control {
  width: 170px;
  display: flex;
  flex-direction: column;
}

.tickers-control {
  width: 500px;
}

.tf-control {
  width: 120px;
}

.small-days-control input {
  background: transparent;
  border: none;
  text-align: center;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;

}

.small-switch-control {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mat-mdc-select-value {
  text-align: center;
}

.mat-button-toggle {
  border: none !important;
}

.cdk-overlay-connected-position-bounding-box>.cdk-overlay-pane {
  width: fit-content !important;
}

.small-select-time>section .mat-mdc-checkbox>div>label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
}

mat-toolbar>div.small-refresh>section>button>mat-icon {
  position: relative;
  top: -2px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 4px 12px !important;
}

div.pages-menu>button {
  color: var(--header-menu-item-color) !important;
}

.mat-toolbar section .mat-mdc-checkbox>div>div>div.mdc-checkbox__background {
  display: none !important;
  width: 5px !important;
}

.vertical-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.vertical-middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.small-value-control input:focus,
.small-value-control input:focus-visible {
  outline: none;
  border: none;
}

.small-value-control input {
  text-align: right;
  border: none;
  //width: 100px;
  //border: 1px solid rgba(0,0,0,0.5);
}

.small-select-time div>label>mat-icon,
body>app-root>main>div>cumulative>mat-toolbar>div.small-date-control>section>mat-datepicker-toggle>button>svg {
  //color: var(--content-text-color) !important;
}

body>app-root>main>div>daily>mat-toolbar>div.small-date-control>section>mat-datepicker-toggle>button>svg,
body>app-root>main>div>bar>mat-toolbar>div.small-date-control>section>mat-datepicker-toggle>button>svg,
body>app-root>main>div>cumulative>mat-toolbar>div.small-date-control>section>mat-datepicker-toggle>button:disabled>svg {
  color: var(--content-text-muted-color) !important;
}

//выпадающее меню
.cdk-overlay-pane {
  height: fit-content;
  top: 40px;
}
.mat-mdc-menu-content {
  background-color: var(--controls-neutral-control-color-20);
}
.mat-mdc-menu-item-text {
  color: var(--content-text-color);
}